
































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import Report16 from '@carbon/icons-vue/es/report/16'
import View16 from '@carbon/icons-vue/es/view/16'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    GenericModalDelete,
    View16,
    Report16,
    Ticket16,
    Edit16
  }
})
export default class EventStudiesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  eventId = this.$route.params.id
  studies = []
  filteredData = []
  extended = this.$route.query.extended

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/event/${this.eventId}/study/grades`)
      .then(response => {
        this.studies = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
